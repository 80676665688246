@import "../../../variables";
@import "../../../../components/headers";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.large-banner-container {
    background-color: $slate;

    .large-banner {
        position: relative;
    }

    .large-banner-container {
        position: relative;
        display: block;
        width: 100%;

        .large-banner-background {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: 250px;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                padding-bottom: 400px;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 500px;
            }

            .background-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }

            .background-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .background-video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                > div {
                    flex-grow: 1;
                }

                &.desktop-video {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                &.mobile-video {
                    width: 150%;
                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }
            }
        }
    }

    .title {
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 12px;
    }

    .body {
        font-size: $lg-font-size;
        width: 100%;
    }

    .desktop-banner {
        width: 100%;
        max-width: 670px;
        color: #fff;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;

        .body {
            text-align: center;
        }
    }

    .mobile-banner {
        padding: 15px 35px 20px;
        background-color: $white;
        text-align: center;

        .title {
            padding-bottom: 5px;
        }

        .body {
            font-size: $base-font-size;
        }
    }
}
